import { CoreAppState } from '../state/core-app.state';
import { BaseResourceService, httpOptions } from './base-resource.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CustomerProfileResourceTypeMappings } from '@aa/nest/resource';
import { environment } from '../environments/environment';

// @aa:gen-ignore

@Injectable()
export class CustomerProfileService extends BaseResourceService<CustomerProfileResourceTypeMappings> {
  constructor(
    protected override readonly http: HttpClient,
    protected override readonly store: Store<CoreAppState>,
  ) {
    super(http, store, 'customer-profiles');
  }

  checkUsername(username: string) {
    return this.http.post<{ available: boolean }>(
      `${environment.resourceService}/customer-profiles/check-username`,
      {
        username,
      },
      { ...httpOptions },
    );
  }
}
