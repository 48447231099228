import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Input,
  OnChanges,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import {
  MatPaginator,
  MatPaginatorModule,
  PageEvent,
} from '@angular/material/paginator';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { BehaviorSubject, firstValueFrom, map, Observable, of } from 'rxjs';

export interface DataTableConfig<RowT = any> {
  title?: string;
  subtitle?: string;
  columns: DataTableColumnConfig<RowT>[];
  rowActions?: DataTableRowAction<RowT>[];
  displayedColumnKeys$?: Observable<string[]>;

  initialPageSize?: number;
  alternateRowColors?: boolean;
  hideTableHeaders?: boolean;
  hidePagination?: boolean;
  hideShadow?: boolean;
  shrinkTitle?: boolean;

  data$: Observable<RowT[]>;
  numItems$: Observable<number>;

  onAdd?: () => any;
  onSortChange?: (sort: Sort) => any;
  onPaginationChange?: (sort: PageEvent) => any;
}

interface PreviewCardAttribute {
  label: string;
  value?: any;
}

export interface DataTableColumnConfig<RowT = any> {
  key: string;
  label: string;
  labelType?: 'text' | 'icon';
  valueType?:
    | 'text'
    | 'icon'
    | 'button'
    | 'table'
    | 'nested-table'
    | 'nested-table-trigger'
    | 'html'
    | 'preview-card';
  isSortable?: boolean;
  minWidth?: string;
  accessor?: (row: RowT) => any;
  previewAttributeAccessor?: (row: RowT) => PreviewCardAttribute[];
  getLinkValue?: (row: RowT) => string;
  getRouterLinkValue?: (row: RowT) => string;
  getBackgroundColor?: (row: RowT) => string;
  getTextColor?: (row: RowT) => string;
  getStyle?: (row: RowT) => object;
  getHeaderStyle?: () => object;
}

export interface DataTableRowAction<RowT = any> {
  key: string;
  label: string;
  icon?: string;
  onClick?: (row: RowT) => any;
}

@Component({
  selector: 'aa-data-table',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
  ],
  templateUrl: './data-table.component.html',
  styleUrl: './data-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataTableComponent implements OnChanges {
  @Input({ required: true }) tableConfig!: DataTableConfig;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  displayedColumnKeys$!: Observable<string[]>;
  displayedNestedTableKeys$ = new BehaviorSubject<[string, number][]>([]);

  constructor(private readonly destroyRef: DestroyRef) {}

  ngOnChanges() {
    if (!this.tableConfig.displayedColumnKeys$) {
      this.tableConfig.displayedColumnKeys$ = of(
        this.tableConfig.columns.map((c) => c.key),
      );
    }

    this.displayedColumnKeys$ = this.tableConfig.displayedColumnKeys$.pipe(
      map((keys) =>
        (this.tableConfig.rowActions
          ? [...keys.filter((key) => key != 'rowActions'), 'rowActions']
          : keys
        ).filter((key) =>
          this.tableConfig.columns.find((c) => c.key == key)?.valueType ==
          'nested-table'
            ? false
            : true,
        ),
      ),
      takeUntilDestroyed(this.destroyRef),
    );

    if (this.paginator) {
      this.tableConfig.numItems$.subscribe((numItems) => {
        this.paginator.length = numItems;
      });
      if (this.tableConfig.initialPageSize) {
        this.paginator.pageSize = this.tableConfig.initialPageSize;
      }

      this.paginator.page
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((page) => {
          if (this.tableConfig.onPaginationChange)
            this.tableConfig.onPaginationChange(page);
        });
    }
  }

  getValue<RowT = any>(row: RowT, columnConfig: DataTableColumnConfig<RowT>) {
    if (columnConfig.accessor) {
      return columnConfig.accessor(row);
    }
    return (row as any)[columnConfig.key];
  }

  combineStyles(styleA: any, styleB: any) {
    return { ...styleA, ...styleB };
  }

  getColumnsWithNestedTables() {
    return this.tableConfig.columns.filter(
      (c) => c.valueType == 'nested-table',
    );
  }

  async toggleNestedTable(tableKey: string, rowIndex: number) {
    const displayedKeys = await firstValueFrom(this.displayedNestedTableKeys$);

    if (
      displayedKeys.find((item) => item[0] == tableKey && item[1] == rowIndex)
    ) {
      this.displayedNestedTableKeys$.next(
        displayedKeys.filter((k) => k[0] != tableKey || k[1] != rowIndex),
      );
    } else {
      this.displayedNestedTableKeys$.next([
        ...displayedKeys,
        [tableKey, rowIndex],
      ]);
    }
  }

  displayedNestedTableContains(
    displayed: [string, number][],
    tableKey: string,
    rowIndex: number,
  ) {
    return !!displayed.find(
      (item) => item[0] == tableKey && item[1] == rowIndex,
    );
  }
}
