<div class="container">
  <div class="header">
    <div class="details">
      @if (tableConfig.title) {
        @if (tableConfig.shrinkTitle) {
          <h2>{{ tableConfig.title }}</h2>
        } @else {
          <h1>{{ tableConfig.title }}</h1>
        }
      }
      @if (tableConfig.subtitle) {
        <h2>{{ tableConfig.subtitle }}</h2>
      }
    </div>
    <div class="actions">
      @if (tableConfig.onAdd) {
        <button mat-flat-button color="primary" (click)="tableConfig.onAdd()">
          Add
          <mat-icon iconPositionEnd>add</mat-icon>
        </button>
      }
    </div>
  </div>

  <div class="no-padding" [ngClass]="{ card: !tableConfig.hideShadow }">
    <div class="table-holder">
      <table
        mat-table
        multiTemplateDataRows
        [dataSource]="tableConfig.data$"
        [ngClass]="{ 'alternate-rows': tableConfig.alternateRowColors }"
        matSort
        (matSortChange)="
          tableConfig.onSortChange ? tableConfig.onSortChange($event) : null
        ">
        <!-- columns -->
        @for (column of tableConfig.columns; track column.key) {
          <ng-container [matColumnDef]="column.key">
            <!-- label -->
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              [disabled]="!column.isSortable"
              [ngStyle]="column.getHeaderStyle ? column.getHeaderStyle() : {}">
              @if (column.labelType == 'icon') {
                <mat-icon>{{ column.label }}</mat-icon>
              } @else {
                {{ column.label }}
              }
            </th>
            <!-- value -->
            <td
              mat-cell
              *matCellDef="let row; let index = dataIndex"
              [attr.colspan]="
                column.valueType == 'nested-table'
                  ? (displayedColumnKeys$ | async)?.length
                  : 1
              "
              [ngStyle]="
                combineStyles(
                  {
                    cursor:
                      column.valueType == 'preview-card'
                        ? 'pointer'
                        : undefined,
                    color: column.getTextColor
                      ? column.getTextColor(row)
                      : undefined,
                    backgroundColor: column.getBackgroundColor
                      ? column.getBackgroundColor(row)
                      : undefined,
                    display:
                      column.valueType == 'nested-table'
                        ? 'table-cell'
                        : undefined,
                    'min-width': column.minWidth ?? '10rem'
                  },
                  column.getStyle ? column.getStyle(row) : {}
                )
              ">
              @if (column.valueType == 'icon') {
                <mat-icon>{{ getValue(row, column) }}</mat-icon>
              } @else if (column.valueType == 'button') {
                <!-- TODO: implement button table value logic -->
              } @else if (column.valueType == 'nested-table-trigger') {
                <button
                  mat-icon-button
                  (click)="
                    toggleNestedTable(getValue(row, column), row.id ?? index)
                  ">
                  <mat-icon>{{
                    displayedNestedTableContains(
                      (displayedNestedTableKeys$ | async) ?? [],
                      getValue(row, column),
                      row.id ?? index
                    )
                      ? 'expand_less'
                      : 'expand_more'
                  }}</mat-icon>
                </button>
              } @else if (column.valueType == 'table') {
                <aa-data-table
                  [tableConfig]="getValue(row, column)"></aa-data-table>
              } @else if (column.valueType == 'nested-table') {
                @if (
                  displayedNestedTableContains(
                    (displayedNestedTableKeys$ | async) ?? [],
                    column.key,
                    row.id ?? index
                  )
                ) {
                  <aa-data-table
                    [tableConfig]="getValue(row, column)"></aa-data-table>
                }
              } @else if (column.valueType == 'html') {
                <div [innerHTML]="getValue(row, column)"></div>
              } @else if (column.getRouterLinkValue) {
                <a [routerLink]="column.getRouterLinkValue(row)">
                  {{ getValue(row, column) }}
                </a>
              } @else if (column.getLinkValue) {
                <a target="_blank" [href]="column.getLinkValue(row)">
                  {{ getValue(row, column) }}
                </a>
              } @else {
                <p>{{ getValue(row, column) }}</p>
              }

              @if (column.valueType == 'preview-card') {
                <div class="card preview-card">
                  <table>
                    @for (
                      attribute of column.previewAttributeAccessor?.(row);
                      track $index
                    ) {
                      <tr>
                        <td class="title">{{ attribute.label }}</td>
                        <td class="value">{{ attribute.value }}</td>
                      </tr>
                    }
                  </table>
                </div>
              }
            </td>
          </ng-container>
        }

        @if (tableConfig.rowActions) {
          <ng-container matColumnDef="rowActions" stickyEnd>
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row">
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>
              </button>

              <mat-menu #menu="matMenu" class="menu">
                @for (action of tableConfig.rowActions; track action.key) {
                  <button
                    mat-flat-button
                    (click)="action.onClick ? action.onClick(row) : null">
                    {{ action.label }}
                    @if (action.icon) {
                      <mat-icon iconPositionEnd>{{ action.icon }}</mat-icon>
                    }
                  </button>
                }
              </mat-menu>
            </td>
          </ng-container>
        }

        <!-- headers -->
        @if (!tableConfig.hideTableHeaders) {
          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumnKeys$ | async"></tr>
        }
        <!-- cells -->
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumnKeys$ | async"></tr>
        <!-- nested table cells -->
        @for (column of getColumnsWithNestedTables(); track column.key) {
          <tr
            class="nested-table-row"
            mat-row
            *matRowDef="let row; columns: [column.key]; let index"
            [ngClass]="{
              hidden: displayedNestedTableContains(
                (displayedNestedTableKeys$ | async) ?? [],
                getValue(row, column),
                row.id ?? index
              )
            }"></tr>
        }

        <!-- empty message -->
        <tr class="mat-row" *matNoDataRow>
          <td
            class="mat-cell no-data-message"
            [attr.colspan]="(displayedColumnKeys$ | async)?.length">
            No data.
          </td>
        </tr>
      </table>
    </div>

    @if (!tableConfig.hidePagination) {
      <mat-paginator
        [pageSizeOptions]="[10, 25, 100]"
        showFirstLastButtons></mat-paginator>
    }
  </div>
</div>
