import { ActionLogResourceTypeMappings } from '@aa/nest/resource';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
  DataTableColumnConfig,
  DataTableConfig,
} from '../../components/data-table/data-table.component';
import { actionLogActions } from '../../state/action-log/action-log.actions';
import { selectActionLogState } from '../../state/action-log/action-log.reducer';
import {
  BaseResourceListViewComponent,
  baseResourceListViewImports,
} from '../base-resource-list-view/base-resource-list-view.component';
import { formatDateTime } from '@aa/ts/common';
import { of } from 'rxjs';
import { titleize, underscore } from 'inflection';

@Component({
  selector: 'aa-action-logs-list-view',
  standalone: true,
  imports: [...baseResourceListViewImports],
  templateUrl:
    '../../../../../core/src/lib/views/base-resource-list-view/base-resource-list-view.component.html',
  styleUrl:
    '../../../../../core/src/lib/views/base-resource-list-view/base-resource-list-view.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionLogsListViewComponent
  extends BaseResourceListViewComponent<ActionLogResourceTypeMappings>
  implements OnInit
{
  title = 'Action Logs';
  columns = [
    { key: 'id', label: 'Id' },
    {
      key: 'formatted',
      label: 'Action',
      valueType: 'html',
      accessor: (row) => {
        const vowels = 'aeiou';
        let name;
        if (row.user?.customerProfileId) {
          name = `Customer <strong>${row.user.customerProfile!.firstName} ${
            row.user.customerProfile!.lastName
          }</strong>`;
        } else {
          name = `Staff Member <strong>${row.user?.staffProfile!
            .firstName} ${row.user?.staffProfile!.lastName}</strong>`;
        }

        const verb = row.didCreateResource
          ? 'created'
          : row.didUpdateResource
            ? 'updated'
            : 'deleted';

        let resourceDescription = `<strong>${row.resourceType}${
          row.resourceDescription ? ` ${row.resourceDescription}` : ''
        }</strong>`;
        if (!row.resourceId) {
          resourceDescription = `${
            vowels.includes(resourceDescription.at(0)!) ? 'an' : 'a'
          } ${resourceDescription}`;
        } else {
          resourceDescription += ` <strong>(${row.resourceId})</strong>`;
        }
        if (row.parentResourceType) {
          resourceDescription += ` on <strong>${row.parentResourceType} (${row.parentResourceId})</strong>`;
        }

        return `<p>${name} ${verb} ${resourceDescription} at ${formatDateTime(
          row.createdAt,
        )}</p>`;
      },
    },
    {
      key: 'nested-table-trigger',
      label: '',
      valueType: 'nested-table-trigger',
      accessor: () => 'payload',
    },
    {
      key: 'payload',
      label: '',
      valueType: 'nested-table',
      accessor: (row) => {
        const payload = row.payload as any;
        const data = payload['data'];
        const effects = payload['effects'];

        let values: any[] = [];

        for (const chunk of !data &&
        !effects &&
        typeof payload == 'object' &&
        payload !== null
          ? [payload]
          : [data, effects]) {
          for (const [key, value] of Object.entries(chunk ?? {})) {
            if (
              ['createdAt', 'updatedAt', 'id'].includes(key) ||
              key.substring(key.length - 2) == 'Id'
            )
              continue;

            let displayValue = (value as any)?.name ?? (value as any)?.label;

            if (!displayValue && value !== null && typeof value == 'object') {
              displayValue = '';
              for (const [nestedKey, nestedValue] of Object.entries(
                (value as any) ?? {},
              )) {
                if (
                  ['createdAt', 'updatedAt', 'id'].includes(nestedKey) ||
                  nestedKey.substring(nestedKey.length - 2) == 'Id'
                )
                  continue;
                displayValue += `<tr><td>${titleize(
                  underscore(nestedKey),
                )}</td><td>${nestedValue}</td></tr>`;
              }
              displayValue = `<table class="alternate-rows">${displayValue}</table>`;
            } else if (!displayValue) {
              displayValue = `${value}`;
            }

            values = [
              ...values,
              {
                key: titleize(underscore(key)),
                value: displayValue,
              },
            ];
          }
        }

        const config: DataTableConfig = {
          columns: [
            {
              key: 'key',
              label: 'Attribute',
            },
            {
              key: 'value',
              label: 'Value',
              valueType: 'html',
            },
          ],
          data$: of(values),
          numItems$: of(values.length),
          hideTableHeaders: true,
          alternateRowColors: true,
          hidePagination: true,
          hideShadow: true,
        };

        return config;
        // const payload = row.payload as any;
        // console.log(payload);
        // const data = payload['data'];
        // const effects = payload['effects'];

        //   let tableContent = '';

        //   if (effects) {
        //     for (const [key, value] of Object.entries(effects ?? {})) {
        //       console.log(key, value);
        //       tableContent += `<tr><td>${key}</td><td>${
        //         (value as any)?.name ??
        //         (value as any)?.label ??
        //         JSON.stringify(value)
        //       }</td></tr>`;
        //     }
        //   } else {
        //     for (const [key, value] of Object.entries(data ?? {})) {
        //       console.log(key, value);
        //       tableContent += `<tr><td>${key}</td><td>${
        //         (value as any)?.name ??
        //         (value as any)?.label ??
        //         JSON.stringify(value)
        //       }</td></tr>`;
        //     }
        //   }

        //   return `<table>${tableContent}</table>`;
      },
    },
    // {
    //   key: 'userId',
    //   label: 'User',
    //   accessor: (row) =>
    //     row.user?.staffProfile
    //       ? `Staff: ${row.user?.staffProfile?.firstName ?? ''} ${
    //           row.user?.staffProfile?.lastName ?? ''
    //         }`
    //       : `Customer: ${row.user?.customerProfile?.firstName ?? ''} ${
    //           row.user?.customerProfile?.lastName ?? ''
    //         }`,
    // },
    // {
    //   key: 'parentResourceId',
    //   label: 'Parent Resource',
    //   accessor: (row) =>
    //     row.parentResourceType
    //       ? `${inflection.titleize(
    //           inflection.underscore(row.parentResourceType ?? ''),
    //         )}-${row.parentResourceId}`
    //       : '',
    // },
    // {
    //   key: 'resource',
    //   label: 'Resource',
    //   accessor: (row) =>
    //     row.resourceType
    //       ? `${inflection.titleize(
    //           inflection.underscore(row.resourceType ?? ''),
    //         )}-${row.resourceId ?? (row.payload as any['id'])}`
    //       : '',
    // },
    // {
    //   key: 'actionType',
    //   label: 'Action Type',
    //   accessor: (row) =>
    // row.didCreateResource
    //   ? 'Created'
    //   : row.didUpdateResource
    //     ? 'Updated'
    //     : 'Deleted',
    // },
    // {
    //   key: 'payload',
    //   label: 'Payload',
    //   valueType: 'html',
    //   getStyle: () => ({
    //     'white-space': 'nowrap',
    //   }),
    //   accessor: (row) =>
    //     getObjectAsHumanReadableString(row.payload ?? {}, '<br>'),
    // },
    // {
    //   key: 'createdAt',
    //   label: 'Timestamp',
    //   accessor: (row) => formatDateTime(row.createdAt),
    //   minWidth: '15rem',
    // },
  ] as DataTableColumnConfig<
    ActionLogResourceTypeMappings['resourceWithRelationsT']
  >[];

  stateSelector = selectActionLogState;
  loadAction = actionLogActions.loadItems({
    query: {
      include: {
        user: {
          include: {
            staffProfile: true,
            customerProfile: true,
          },
        },
      },
      pageSize: this.initialPageSize,
      orderBy: {
        createdAt: 'desc',
      },
    },
  });
  actions = actionLogActions;

  override async ngOnInit() {
    await super.ngOnInit();
    this.tableConfig = {
      ...this.tableConfig,
      onAdd: undefined,
    };
  }

  createFormModalTypeGetter = () => undefined;
  updateFormModalTypeGetter = () => undefined;
}
