import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import {
  authActions,
  CoreAppState,
  selectAuth,
  toggleSidenavIsOpen,
} from '@aa/angular/core';
import { BehaviorSubject, map, Observable, switchMap } from 'rxjs';
import { CommonModule } from '@angular/common';
import { isInStandaloneMode } from '@aa/ts/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export const APP_VERSION = 'V0.6.0';

@Component({
  selector: 'aa-app-bar',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
  ],
  templateUrl: './app-bar.component.html',
  styleUrl: './app-bar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppBarComponent implements AfterViewChecked {
  @Output() showInstallPrompt = new EventEmitter();

  username$: Observable<string>;
  isStaffApp$: Observable<boolean>;

  appVersion = APP_VERSION;

  showInstallAppButton$ = new BehaviorSubject(true);

  constructor(private readonly store: Store<CoreAppState>) {
    this.username$ = this.store
      .select((s) => s.isStaffApp)
      .pipe(
        switchMap((isStaffApp) =>
          this.store
            .select((s) => selectAuth(s).user)
            .pipe(
              map((user) =>
                isStaffApp
                  ? `${user?.staffProfile?.firstName ?? ''} ${
                      user?.staffProfile?.lastName ?? ''
                    }`
                  : `${user?.customerProfile?.username ?? ''}`,
              ),
            ),
        ),
        takeUntilDestroyed(),
      );

    this.isStaffApp$ = this.store
      .select((s) => s.isStaffApp)
      .pipe(takeUntilDestroyed());
  }

  ngAfterViewChecked() {
    if (isInStandaloneMode()) {
      this.showInstallAppButton$.next(false);
    }
  }

  toggleSidenav() {
    this.store.dispatch(toggleSidenavIsOpen());
  }

  installApp() {
    this.showInstallPrompt.emit();
  }

  logout() {
    this.store.dispatch(authActions.logout());
  }
}
